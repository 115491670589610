import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {StorageService} from "../services/storage.service";


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _router: Router,
    private _storageService: StorageService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
   //   console.log(this._storageService.isAuthenticated());
    if (this._storageService.isAuthenticated()) {
      // logged in so return true
      return true;
    } else {
      this._router.navigate(['/login']);
      return false;
    }
    // not logged in so redirect to login page
    
  }
  
}