<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h5 class="bg-ordenes">Órdenes de trabajo</h5>
    </div>
  </div>
  <!-- <button mat-raised-button color="secondary" type="button" (click)="generarOrdenes()">GENERAR ÓRDENES</button> -->
  <!-- <h3>Área: {{reclamosPorArea[0]?.nombre}}</h3> -->
  <div class="row mt-2">
    <div class="col-12">
      <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)" *ngFor="let reclamos of dataSource.data; let i = index" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{reclamos.nombre}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- NUEVA FORMA -->
        <mat-table #table [dataSource]="reclamos.reclamos" class="mat-elevation-z8 mt-4" cdkDropList
            [cdkDropListData]="reclamos.reclamos" (cdkDropListDropped)="dropTable($event, i)" multiTemplateDataRows>

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle(i) : null"
                  [checked]="reclamos.selection.hasValue() && isAllSelected(i)">
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="$event ? reclamos.selection.toggle(row) : null; ver(row)" [checked]="reclamos.selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row, i)">
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="fecha">
              <mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.fecha_ingreso}}</mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="nombre">
              <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.vecino_nombre}} </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="direccion">
              <mat-header-cell *matHeaderCellDef> Dirección </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.reclamo_direccion}}</mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="barrio">
              <mat-header-cell *matHeaderCellDef> Barrio </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.barrio?.nombre}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="motivo">
              <mat-header-cell *matHeaderCellDef> Motivo </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.motivo.descripcion}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="accion">
              <mat-header-cell *matHeaderCellDef> </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.comentario_extra" class="menu-button"
                  [ngClass]="{'rotated' : isExpanded && element == expandedElement}"
                  (click)="isExpanded = !isExpanded;expandedElement = expandedElement === element ? null : element">
                  <img src="../../../assets/images/FlechaAbajo.png" class="icons"></mat-icon>
              </mat-cell>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column -->
            <ng-container matColumnDef="expandedDetail">
              <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="example-element-description">
                    {{element.comentario_extra}}
                    <span class="example-element-description-attribution"> </span>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragData]="row"
              class="example-element-row" [class.example-expanded-row]="expandedElement === element"></mat-row>
            <mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="example-detail-row"></mat-row>
          </mat-table>
          <!-- FIN NUEVA FORMA-->

      </mat-expansion-panel>
      <a id="btn-generar-ord"  class="bg-ordenes btn-gen-ord "
(click)="generar()">GENERAR ORDEN</a>
    </div>
  </div>
</div>
<!-- <div class="row" style="z-index:9999; position: fixed; bottom: 0px; width: 100%; height: 60px;">
  <div class="col-12" style="padding-right: 53px; padding-left: 53px; padding-top: 10px;">
    <div class="container">
      <div class="row">
        <div class="col-12" style="text-align: right; padding-right: 53px; padding-left: 53px;">
          <button id="btn-generar-ord" mat-raised-button class="bg-ordenes" type="button"
            (click)="generar()">GENERAR ORDEN</button>
          
        </div>
      </div>
    </div>
  </div>
</div> -->
