import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Output() mensaje: EventEmitter<any[]> = new EventEmitter<any[]>();
  listaMenu: menu[] = []
  listaMenuFiltrada: menu[] = []

  permisos: any;
  opened: boolean = false;
  user: any;
  constructor(public _generalServices: GeneralService, public _storageService: StorageService) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('currentUser'))
  //  console.log(this.user)
    this.getPermisos()
    setTimeout(() => {
      //  this.emitirMensaje()
    }, 200);


  }
  
  getPermisos() {
    this._generalServices.getPermisos()
      .subscribe(res => { let data: any = res; this.permisos = data; this.emitirMensaje() })
  }
  emitirMensaje() {
    // Usando la variable emitimos el valor que queremos enviar
    this._generalServices.getMenu(this.user.user.username)
      .subscribe(res => {
        let data: any = res
        //  this.listaMenu = data;
        for (let i = 0; i < data[0].puntosmenu.length; i++) {
          switch (data[0].puntosmenu[i].id_menu) {
            case '02':
              this.listaMenu.push({ url: '/seguimiento', nombre: data[0].puntosmenu[i].nombre, id: data[0].puntosmenu[i].id, clase: 'seguimiento', color: 'rgba(227, 0, 22, 0.25)', img: '../assets/images/menu/SegReclamos.png' })
              break;
            case '04':
              this.listaMenu.push({ url: '/consulta-ordenes', nombre: data[0].puntosmenu[i].nombre, id: data[0].puntosmenu[i].id, clase: 'consulta', color: 'rgba(177, 201, 0, 0.25)', img: '../assets/images/menu/BuscarODT.png' })
              break;
            case '05':
              this.listaMenu.push({ url: '/informes', nombre: data[0].puntosmenu[i].nombre, id: data[0].puntosmenu[i].id, clase: 'informes', color: 'rgba(131, 37, 97, 0.25)', img: '../assets/images/menu/Infirmes.png' })
              break;
            case '01':
              this.listaMenu.push({ url: '/alta-reclamo', nombre: data[0].puntosmenu[i].nombre, id: data[0].puntosmenu[i].id, clase: 'reclamos', color: 'rgba(0, 114, 149, 0.25)', img: '../assets/images/menu/Reclamo.png' })
              break;
            case '03':
              this.listaMenu.push({ url: '/generar-ordenes', nombre: data[0].puntosmenu[i].nombre, id: data[0].puntosmenu[i].id, clase: 'ordenes', color: 'rgba(244, 146, 0, 0.25)', img: '../assets/images/menu/ordeDeTrabajo.png' })
              break;

            default:
              break;
          }
        }
         this.mensaje.emit(this.listaMenu)      })
  }
}
export interface menu {
  url: string,
  nombre: string,
  id: number,
  clase: any,
  color: any,
  img: string,
}