import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  url: string = ""
  preUrl: string = this.config.apiBaseUrl()
  constructor(private _http: HttpClient, private config: AppConfig) {
   // this.preUrl = environment.baseUrl.prefix + environment.baseUrl.dominio;
  }

  getMenu(username) {
    //let url = 'http://localhost:8000/api-token-auth/'
    let api = this.preUrl + '/api/puntosmenuporusuario/?username=' + username
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN') });
    return this._http.get(api, { headers: headers });
  }

  getAreas() {
    let api = this.preUrl + 'api/areas/'
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN') });
    return this._http.get(api, { headers: headers });
  }
  getMotivos() {
    let api = this.preUrl + 'api/motivos/'
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN') });
    return this._http.get(api, { headers: headers });
  }
  getBarrios() {
    let api = this.preUrl + 'api/barrios/'
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN') });
    return this._http.get(api, { headers: headers });
  }

  getPermisos() {
    let api = this.preUrl + '/api/permisos/'
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN') });
    return this._http.get(api, { headers: headers });
  }

  reclamos(body, type) {
    let api = this.preUrl + '/api/reclamos/'
    if (type === 'POST') {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN'), 'X-CSRFToken': sessionStorage.getItem('csrftoken') });
      return this._http.post(api, body, { headers: headers });
    }
    if (type === 'GET') {
      if(body){ api = api +body+'/'}
      let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN') });
      return this._http.get(api, { headers: headers });
    }
    if (type === 'PATCH') {
      api = api + body.id + '/'
      let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN'), 'X-CSRFToken': sessionStorage.getItem('csrftoken') });
      return this._http.patch(api, body, { headers: headers });
    }
  }
  reclamosEstado(id, body){
    let api = this.preUrl + '/api/reclamos/'+id+'/';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN'), 'X-CSRFToken': sessionStorage.getItem('csrftoken') });
    return this._http.patch(api, body, { headers: headers });
  }
  comentarios(body) {
    let api = this.preUrl + '/api/comentarios/'
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN'), 'X-CSRFToken': sessionStorage.getItem('csrftoken') });
    return this._http.post(api, body, { headers: headers });
  }

  getReclamosFiltro(fechaDesde, fechaHasta,motivo,barrio,estado,ordenId,area) {
    let api = this.preUrl + `/api/reclamos/?fecha_ingreso__gte=${fechaDesde}&fecha_ingreso__lte=${fechaHasta}&motivo=${motivo}&estado=${estado}&barrio=${barrio}&orden_trabajo__id=${ordenId}&motivo__area=${area}`
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN'), 'X-CSRFToken': sessionStorage.getItem('csrftoken') });
      return this._http.get(api, { headers: headers });
  }

  getReclamosArea() {
    //let url = 'http://localhost:8000/api-token-auth/'
    let api = this.preUrl + '/api/reclamosporarea/'
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + sessionStorage.getItem('TOKEN') });
    return this._http.get(api, { headers: headers });
  }
}
