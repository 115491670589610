<div *ngIf="loading" class="loading-container flex-content-center">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
<div class="container" style="height: 100%;">
  <div class="row">
    <div class="col-12">
      <h5 class="bg-reclamos">Ingreso del reclamo</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-expansion-panel [expanded]="true" hideToggle>
        <!-- <mat-expansion-panel-header color="primary">
          <mat-panel-title>
            Ingreso de reclamo
          </mat-panel-title>
        </mat-expansion-panel-header> -->

        <form id="reclamo-form" [formGroup]="form" (ngSubmit)="cargarReclamo()" #formDirective="ngForm" novalidate>
          <div class="row mt-4" style="font-size: 12px;">
            <div class="col-4">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Nombre y Apellido</mat-label>
                <input id="vecino_nombre" matInput placeholder="" formControlName="vecino_nombre">
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Teléfono</mat-label>
                <input id="vecino_telefono" matInput placeholder="" formControlName="vecino_telefono">
              </mat-form-field>
            </div>
            <div class="col-4">
              <div style="text-align: right">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <input id="fecha_ingreso" matInput formControlName="fecha_ingreso" readonly>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            
            <!-- <div class="col-4">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Direccion</mat-label>
                <input id="vecino_direccion" matInput placeholder="" formControlName="vecino_direccion">
              </mat-form-field>
            </div> -->
            
          </div>
          <div class="row" style="font-size: 12px;">
            <div class="col-4">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Barrio</mat-label>
                <mat-select id="barrio" formControlName="barrio_id">
                  <mat-option *ngFor="let barrio of listaBarrios" value={{barrio.id}}>
                    {{barrio.nombre}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-8">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Motivo</mat-label>
                <mat-select id="motivo" formControlName="motivo_id" (selectionChange)="select($event.value)" required>
                  <mat-optgroup *ngFor="let area of listaAreas" [label]="area.nombre">
                    <ng-container *ngFor="let motivo of listaMotivos">
                      <mat-option *ngIf="motivo.area === area.id" value={{motivo.id}}>
                        {{motivo.descripcion}}
                      </mat-option>
                    </ng-container>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="font-size: 12px;">
            <div class="col-12">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Observaciones</mat-label>
                <input id="comentario_extra" matInput placeholder="" formControlName="comentario_extra" [required]="obligado">
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="font-size: 12px;">
            <div class="col-11">
              <mat-form-field appearance="fill" style="width: 100%;" *ngIf="!sinNomenclar">
                <input id="localizacion" matInput (keydown.enter)="$event.preventDefault()" placeholder="Ingrese dirección del reclamo *"
                  autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search
                  formControlName="localizacion">
              </mat-form-field>
              <mat-form-field appearance="fill" style="width: 100%;" *ngIf="sinNomenclar">
                <input id="reclamo_direccion" matInput  placeholder="Ingrese dirección del reclamo sin nomenclar*"
                formControlName="reclamo_direccion">
              </mat-form-field>
            </div>
            <div class="col-1" style="text-align:right; padding-left:0px">
              <div class="button">
                <input type="button" matTooltip="Domicilio sin nomenclar"  (click)="onChange($event);" class="startBtn" value="S/N">
              </div>
              <!-- <mat-checkbox [checked]="checked" [formControl]="direccionAlt"
              (change)="onChange($event); false" matTooltip="Domicilio sin nomenclar"></mat-checkbox> -->
            </div>
            <div class="col-12">
              <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" 
                 [disableDefaultUI]='true' #mapa> <!--[styles]="styleMap" -->
                <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.long"
                  [markerDraggable]="false" [label]="m.label" (mouseOver)="onMouseOver(infoWindow, $event)"
                  (mouseOut)="onMouseOut(infoWindow, $event)">

                  <agm-info-window #infoWindow>
                    <span><b>Dirección: </b>{{ m.direccion }}</span><br>
                    <span><b>Motivo: </b>{{ m.motivo }}</span>
                  </agm-info-window>
                </agm-marker>
              </agm-map>
            </div>
          </div>

        </form>
        <mat-action-row style="display: inline-block;">
        </mat-action-row>
      </mat-expansion-panel>
    </div>
  </div>
</div>
<div class="row"
style="z-index:9999; position: fixed; bottom: 0px; width: 100%; height: 60px;">
<div class="col-12" style="padding-right: 53px; padding-left: 53px; padding-top: 10px;">
  <div class="container">
    <div class="row">
      <div class="col-12" style="text-align: right; padding-right: 53px; padding-left: 53px;">
        <button id="btn-guardar" mat-raised-button class="bg-reclamos" type="submit" form="reclamo-form" style="width:150px" *ngIf="!sinNomenclar">GUARDAR</button>
        
        <button id="btn-guardar" (click)="openDialog()" mat-raised-button class="bg-reclamos" type="button" form="reclamo-form" style="width:150px" *ngIf="sinNomenclar">GUARDAR</button>
      </div>
    </div>
  </div>
</div>
</div>