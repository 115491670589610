<div *ngIf="loading" class="loading-container flex-content-center">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
<div class="container-fluid">
  <div class="row" >
    <div class="col-12" >
        <h5 class="bg-seguimiento">Seguimiento de reclamos</h5>
    </div>
</div>
  <div class="row mt-2">
    <div class="col-12">
      <mat-accordion #myaccordion="matAccordion" multi class="example-headers-align">
        <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)"  hideToggle *ngFor="let reclamos of dataSource.data; let i = index" >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{reclamos.nombre}}
            </mat-panel-title>
          </mat-expansion-panel-header>
  
          <mat-table #table [dataSource]="reclamos.reclamos" class="mat-elevation-z8 mt-4" multiTemplateDataRows>

            <!-- Position Column -->
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.id }}</mat-cell>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="fecha">
              <mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.fecha_ingreso }}</mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="nombre">
              <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.vecino_nombre}} </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="direccion">
              <mat-header-cell *matHeaderCellDef> Dirección </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.reclamo_direccion}}</mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="barrio">
              <mat-header-cell *matHeaderCellDef> Barrio </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.barrio?.nombre}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="motivo">
              <mat-header-cell *matHeaderCellDef> Motivo </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.motivo.descripcion}} </mat-cell>
            </ng-container>

            <!-- Comment Column -->
            <ng-container matColumnDef="accion">
              <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <ng-container>
                  <mat-icon color="primary" style="cursor: pointer;" matTooltip="Agregar comentario" (click)="openDialog('Comentario',element)">
                    <img src="../../../assets/images/Mensaje.png" class="icons"></mat-icon>
                  <mat-icon color="primary" style="cursor: pointer; margin-left: 5px !important; margin-right: 5px !important;" matTooltip="Finalizar reclamo" (click)="openDialog('Finalizar',element)">
                    <img src="../../../assets/images/Finalizar.png" class="icons"></mat-icon>
                  <mat-icon *ngIf="element.comentario_extra || element.comentarios.length > 0" class="menu-button"
                    [ngClass]="{'rotated' : isExpanded && element == expandedElement}"
                    (click)="isExpanded = !isExpanded;expandedElement = expandedElement === element ? null : element">
                    <img src="../../../assets/images/FlechaAbajo.png" class="icons"></mat-icon>
                </ng-container>
              </mat-cell>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column -->
            <ng-container matColumnDef="expandedDetail">
              <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail" style="padding-left: 0px; width: 100%"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div class="example-element-description">
                    <p [innerHTML]="element.comentario_extra"></p>
                    <!-- <span class="example-element-description-attribution"> </span> -->
                    <div class="inbox_chat" style="overflow: hidden;">
                      <div class="chat_list active_chat" *ngFor="let coment of element.comentarios">
                        <div class="chat_people">
                          <div class="chat_ib">
                            <h5>{{coment.user_username}} <span class="chat_date">{{coment.fecha_comentario}}</span></h5>
                            <p>{{coment.comentario}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"></mat-row>
            <mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="example-detail-row"></mat-row>
          </mat-table>

        </mat-expansion-panel>
        
      </mat-accordion>

    </div>
  </div>
</div>

<div style="padding: 32px">
  <div class="example-container mat-elevation-z8">
  </div>
</div>