<div *ngIf="loading" class="loading-container flex-content-center">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
<div class="aligner">
  <mat-card class="aligner-center-item" style="padding: 0; border-radius: 6px !important; min-width: 350px;"
    *ngIf="!confirmPass">
    <mat-card-title color="primary"
      style="text-align:center; border-radius: 6px !important; padding:16px; background-color: #3f51b5; color:#fff">
      INGRESE SU EMAIL
    </mat-card-title>
    <mat-card-content style="padding: 16px">
      <form id="reset-form" [formGroup]="resetForm" (ngSubmit)="resetPassword()" #formDirective="ngForm" novalidate>
        <mat-form-field style="width: 100%; margin-bottom: 5px;"
          [ngClass]="{'mat-input-invalid': submitted && error?.code == 1}">
          <input matInput type="email" formControlName="email" placeholder="Email" />
        </mat-form-field>
        <a style="cursor: pointer" [routerLink]="['/login']"> Ir al login</a>
      </form>
    </mat-card-content>
    <mat-card-actions style="text-align: center; padding: 16px">
      <button mat-stroked-button type="submit" [disabled]="!resetForm.valid" form="reset-form" color="primary" style="width: 100%;">INGRESAR</button>
    </mat-card-actions>
  </mat-card>

  <mat-card class="aligner-center-item" style="padding: 0; border-radius: 6px !important;" *ngIf="confirmPass">
    <mat-card-title color="primary"
      style="text-align:center; border-radius: 6px !important; padding:16px; background-color: #3f51b5; color:#fff">
      INGRESE SU NUEVA CONTRASEÑA
    </mat-card-title>
    <mat-card-content style="padding: 16px">
      <form id="confirm-form" [formGroup]="confirmForm" (ngSubmit)="confirmPassword()" novalidate #formDirective="ngForm">
        <mat-form-field style="width: 100%; margin-bottom: 10px;">
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Password" />
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <mat-form-field style="width: 100%; margin-bottom: 10px;">
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="confirmPassword" placeholder="Repeat Password" (keyup)=checkPasswords(confirmForm) />
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <!-- <p style="cursor: pointer" [routerLink]="['/']">Iniciar Sesión</p> -->
      </form>
    </mat-card-content>
    <mat-card-actions style="text-align: center; padding: 16px">
      <button mat-stroked-button type="submit" [disabled]="!confirmForm.valid" form="confirm-form" color="primary"
        style="width: 100%;">INGRESAR</button>
    </mat-card-actions>
  </mat-card>
</div>