import { Injectable } from '@angular/core';
import { Http, Response, Headers } from "@angular/http";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators';
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  url: string = ""
  preUrl: string = this.config.apiBaseUrl()
  
  constructor(private _http: HttpClient, private http: Http, private config: AppConfig) {
   // this.preUrl = environment.baseUrl.prefix + environment.baseUrl.dominio;
   }
   
   login(user, pass) {
    console.log(this.config.apiBaseUrl())
   //  let url = 'http://localhost:8000/api-token-auth/'
    let api = this.preUrl + '/api-token-auth/'
    let json = '{"username": "' + user + '","password": "' + pass + '"}';
    let headers = new Headers({ 'Content-Type': 'application/json' });
		return this.http.post(api,
			json, { headers: headers }).pipe(map(res => res.json()));
  }

  resetPassword(email) {
    
    console.log( sessionStorage.getItem('csrftoken'))
    let api = this.preUrl + '/accounts/password-reset/'
    let json = `{"email": "${email}"}`;
		let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-CSRFToken': sessionStorage.getItem('csrftoken') });
    return this._http.post(api, json, { headers: headers })
  }

  confirmPassword(pass, token) {
    let api = this.preUrl + '/accounts/password-reset/confirm/'
    let json = `{"password": "${pass}", "token": "${token}"}`;
		let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-CSRFToken': sessionStorage.getItem('csrftoken') });
    return this._http.post(api, json, { headers: headers });
  }
}
