import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class OrdenTrabajoService {

  url: string = ""
  preUrl: string = this.config.apiBaseUrl()
  constructor(private _http: HttpClient, private config: AppConfig) {
   // this.preUrl = environment.baseUrl.prefix + environment.baseUrl.dominio;
  }

  getReclamosArea() {
    //let url = 'http://localhost:8000/api-token-auth/'
    let api = this.preUrl + '/api/reclamosporarea/'
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT '+ sessionStorage.getItem('TOKEN') });
    return this._http.get(api, { headers: headers });
  }
  postOrdenTrabajo(body) {
    let api = this.preUrl + '/api/ordenesdetrabajo/'
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT '+ sessionStorage.getItem('TOKEN'), 'X-CSRFToken': sessionStorage.getItem('csrftoken') });
    return this._http.post(api, body, { headers: headers });

  }
  getOrdenTrabajo() {
    let api = this.preUrl + '/api/ordenesdetrabajo/'
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT '+ sessionStorage.getItem('TOKEN'), 'X-CSRFToken': sessionStorage.getItem('csrftoken') });
    return this._http.get(api, { headers: headers });

  }
}
