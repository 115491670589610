<div *ngIf="loading" class="loading-container flex-content-center">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>
<div class="container-fluid">
    <div class="row" >
        <div class="col-12" >
            <h5 class="bg-consulta">Consulta de órdenes de trabajo</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8 mt-4" multiTemplateDataRows>

                <!-- Position Column -->
                <ng-container matColumnDef="nro">
                    <th mat-header-cell *matHeaderCellDef> Nro de orden</th>
                    <td mat-cell *matCellDef="let element">{{element.id}}</td>
                </ng-container>

                <!-- Position Column -->
                <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef> Fecha </th>
                    <td mat-cell *matCellDef="let element">{{element.fecha_orden | date: 'dd/MM/yyyy'}}</td>
                </ng-container>

                <ng-container matColumnDef="area">
                    <th mat-header-cell *matHeaderCellDef> Motivo </th>
                    <td mat-cell *matCellDef="let element"> {{element.area.nombre}} </td>
                </ng-container>

                <ng-container matColumnDef="accion">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let row">
                        <!-- <img src="../../../assets/images/Impresión.png" style="width: 100%;"> -->
                        <mat-icon (click)="imprimirOrden(row)" style="cursor: pointer;">
                            <img src="../../../assets/images/Impresión.png" class="icons"></mat-icon>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>