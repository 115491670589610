<mat-toolbar class="color-primary" *ngIf="_storageService.isAuthenticated()">
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon id="btn-menu">menu</mat-icon>
  </button>
  <span [routerLink]="['home']" style="cursor:pointer">Secretaría de Obras y Servicios Públicos</span>
</mat-toolbar>
<app-scroll-to-top></app-scroll-to-top>
<mat-sidenav-container [ngClass]="{'container-top': _storageService.isAuthenticated(), 'container-body': !_storageService.isAuthenticated()}" class="container-top" fullscreen>
  <mat-sidenav mode="over" [(opened)]="opened" *ngIf="_storageService.isAuthenticated()">
    <app-menu (mensaje)="recibirMensaje( $event )"></app-menu>
    <mat-nav-list>

      <div *ngFor="let menu of listaMenu">

        <mat-list-item id="{{menu.clase}}" [routerLink]="[menu.url]" [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{exact: true}" (click)="opened = !opened">
          <span class="full-width">{{menu.nombre}}</span>
          <mat-icon mat-list-icon><img src="{{menu.img}}" style="width: 100%; padding-right: 2px;"></mat-icon>
        </mat-list-item>
      </div>

      <mat-list-item (click)="_storageService.logout(); opened = !opened" >
        <span class="full-width">Cerrar Sesión</span>
        <mat-icon mat-list-icon>power_settings_new</mat-icon>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content [ngStyle]="{'padding':!_storageService.isAuthenticated() ? '0px' : '16px' }">
    <app-login *ngIf="!_storageService.isAuthenticated()&&!_router.url.includes('/accounts')"></app-login>
    <router-outlet></router-outlet>
    
  </mat-sidenav-content>
</mat-sidenav-container>



