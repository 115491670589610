import { map } from 'rxjs/operators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyMaterialModule } from './material'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import { LoginComponent } from './login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IngresoReclamoComponent, ReclamoModal } from './pages/ingreso-reclamo/ingreso-reclamo.component';
import { StorageService } from './services/storage.service';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { MenuComponent } from './shared/menu/menu.component';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { SeguimientoReclamosComponent } from './pages/seguimiento-reclamos/seguimiento-reclamos.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { HttpRequestInterceptor } from './interceptors/http-request-interceptor';
import { VersionCheckService } from './services/versionCheck.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TokenInterceptorsService } from './interceptors/token-interceptors.service';
import { OrdenTrabajoComponent } from './pages/orden-trabajo/orden-trabajo.component';
import { HttpModule } from '@angular/http';
import { WorkersService } from './services/workers.service';
import { HomeComponent } from './pages/home/home.component';
import { ScrollToTopComponent } from './shared/scroll-to-top/scroll-to-top.component';
import { InlineEditComponent } from './pages/seguimiento-reclamos/inline-edit/inline-edit.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { ReportesComponent } from './pages/reportes/reportes.component';
import { ConsultaOrdenesComponent } from './pages/consulta-ordenes/consulta-ordenes.component';
//config file externo
import { APP_INITIALIZER } from '@angular/core';
import {AppConfig} from './app.config';


export function initConfig(config: AppConfig) {
  return () => config.loadAppConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    IngresoReclamoComponent,
    ResetPasswordComponent,
    MenuComponent,
    SeguimientoReclamosComponent,
    OrdenTrabajoComponent,
    HomeComponent,
    ScrollToTopComponent,
    InlineEditComponent,
    ReportesComponent,
    ConsultaOrdenesComponent,
    ReclamoModal
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MyMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragDropModule,
    HttpModule,
    SatPopoverModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyASDG5IsPyHy3P6H2IiK1KXZEEutD4AESU',
      libraries: ['places']
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  // { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorsService, multi: true },
    VersionCheckService, WorkersService, Title,
    AppConfig,
          { 
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfig],
            multi: true 
          },
    ],
  bootstrap: [AppComponent],
  entryComponents: [InlineEditComponent, ReclamoModal],
})
export class AppModule { }
