import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class WorkersService {

  constructor(private swUpdate: SwUpdate, private snackbar: MatSnackBar) {
    this.swUpdate.available.subscribe(evt => {
      const snack = this.snackbar.open('Update Available', 'Reload');
      snack
        .onAction()
        .subscribe(() => {
          window.location.reload();
        });

      setTimeout(() => {
        snack.dismiss();
      }, 6000);
    })
  }
  checkUpdates(){
    if (!this.swUpdate.isEnabled) {
      console.log('Nope');
     
      return false
    } else {
      this.swUpdate.activateUpdate()
      return true
    }
      
  }
}
