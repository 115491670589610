import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, NgZone, Inject } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from 'src/app/services/loading.service';
import { delay } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MAT_CHECKBOX_CLICK_ACTION, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-ingreso-reclamo',
  templateUrl: './ingreso-reclamo.component.html',
  styleUrls: ['./ingreso-reclamo.component.scss'],
  providers: [{ provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check' }]
})


export class IngresoReclamoComponent implements OnInit {
  obligado: boolean = false
  existe: boolean = false;
  checked: boolean = false;
  @ViewChild('ref', { static: true }) ref;
  street_number: string = "";
  street_name: string = "";
  form: FormGroup;
  latitude: number = -32.74822;
  longitude: number = -60.73232;
  zoom: number;
  address: string;
  markers: any[] = [];

  //   labelOptions = {
  //     color: 'white',
  //     fontFamily: '',
  //     fontSize: '14px',
  //     fontWeight: 'bold',
  //     text: "R"
  // }
  private geoCoder;

  @ViewChild('search', { static: false })

  public searchElementRef: ElementRef;

  loading: boolean = true;
  auth: boolean = false;
  sinNomenclar: boolean = false;
  listaMenu: menu[] = [];
  fechaActual: Date = new Date()
  @Output() menus: EventEmitter<menu[]> = new EventEmitter<[]>();

  listaAreas: any;
  listaMotivos: any;
  listaEstados: any;
  listaBarrios: any;
  listaReclamos: any;
  estado: string = 'Pendiente';
  direccionAlt = new FormControl(false)
  puntoAsignado: boolean = false;
  @ViewChild('formDirective', { static: false }) private formDirective: NgForm;


  options = {
    componentRestrictions: { country: "AR" }
  };

  constructor(public _generalService: GeneralService, private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader, public _toastr: ToastrService, private _loading: LoadingService,
    private ngZone: NgZone, public dialog: MatDialog) {

    this.form = this.formBuilder.group({
      fecha_ingreso: [this.extraerFecha(this.fechaActual)],
      vecino_nombre: [''],
      vecino_direccion: [null],
      vecino_telefono: [''],
      vecino_email: [''],
      comentario_extra: [''],
      reclamo_direccion: [''],
      barrio_id: [''],
      localizacion: [''],
      motivo_id: [''],
      estado: [this.estado]
    })
  }
  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }

  onChange(event) {
    //console.log('onChange event.checked '+event.checked);
    if(!this.sinNomenclar){event.srcElement.classList.add("visited"); this.sinNomenclar =true } else {event.srcElement.classList.remove("visited"); this.sinNomenclar = false}
    if (this.sinNomenclar) {
      this.latitude = null;
      this.longitude = null;
      this.form.controls['localizacion'].setValue(null)
      
      //  this.form.get('localizacion').clearValidators()
      // this.form.get('reclamo_direccion').setValidators([Validators.required])
    } else {
      setTimeout(() => {
        this.initMap()
      }, 1000);

    }
  }

  select(event) {
    console.log(event)
    for (let i = 0; i < this.listaMotivos.length; i++) {
      if (event === this.listaMotivos[i].id.toString()) {
        if (this.listaMotivos[i].comentario_obligatorio === true) {
          this.obligado = true
        } else { this.obligado = false }
      }

    }
  }
  ngOnInit() {
    this.listenToLoading();
    this.llenarCombos()
    this.getReclamos()
    this.initMap()
    this.auth = true

  }

  openDialog(): void {
    if( this.form.value.reclamo_direccion !== null && this.form.value.reclamo_direccion !== "" ){
      let confirm: boolean = false;
      const dialogRef = this.dialog.open(ReclamoModal, {
        width: '400px', maxHeight: '350px',
        data: { name: confirm }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log(this.form.value)
        if (result === true) { this.cargarSinNomenclar() }
      });
    } else {
      this._toastr.error('Complete correctamente la dirección');
    }

  }

  initMap() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, this.options);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result

          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log(place)
          if (this.direccionValida(place.address_components)) {
            console.log('address ', this.address)
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            if (this.puntoAsignado) { this.markers.pop() } else { this.puntoAsignado = true }
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 20;
            this.markers.push({ lat: this.latitude, long: this.longitude, label: '' })

            console.log(this.latitude, ',', this.longitude)
          }

        });
      });
    });
  }
  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  cargarSinNomenclar() {
    if(this.form.value.reclamo_direccion !== null && this.form.value.reclamo_direccion !== "") {
      this.form.value.estado = this.estado
      let fecha = this.form.value.fecha_ingreso.split('/')
      this.form.value.fecha_ingreso = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
      console.log(this.form.value)
      let jsbody = JSON.stringify(this.form.value)
      this._generalService.reclamos(jsbody, 'POST')
        .subscribe(res => {
          this.formDirective.resetForm();
          this.form.controls['fecha_ingreso'].setValue(this.extraerFecha(this.fechaActual))
          this.form.controls['vecino_telefono'].setValue('')
          this.form.controls['estado'].setValue(this.estado)
          this.direccionAlt.setValue(false)
          this.sinNomenclar = false
          this.checked = false
          setTimeout(() => {
            this.initMap()  
          }, 1000);
          
          this.getReclamos()
          this._toastr.success('Reclamo registrado');
        }, error => {
          console.log(error)
          this._toastr.error('Complete los campos requeridos');
        })
    } else {
      this._toastr.error('Complete correctamente la dirección');
    }
    
  }
  cargarReclamo() {
    if (this.address !== "" && this.address !== undefined) {
      this.form.value.localizacion = this.latitude + ',' + this.longitude
      this.form.value.reclamo_direccion = this.address

      this.form.value.estado = this.estado
      let fecha = this.form.value.fecha_ingreso.split('/')
      this.form.value.fecha_ingreso = fecha[2] + '-' + fecha[1] + '-' + fecha[0]
      console.log(this.form.value)
      let jsbody = JSON.stringify(this.form.value)
      this._generalService.reclamos(jsbody, 'POST')
        .subscribe(res => {
          this.formDirective.resetForm();
          this.form.controls['fecha_ingreso'].setValue(this.extraerFecha(this.fechaActual))
          this.form.controls['vecino_telefono'].setValue('')
          this.form.controls['estado'].setValue(this.estado)
          this.getReclamos()
          this._toastr.success('Reclamo registrado');
          
        }, error => {
          console.log(error)
          this._toastr.error('Complete los campos requeridos');
        })
    } else {
      if (this.address !== "" && this.address !== undefined){
        this._toastr.error('Complete los campos requeridos');
        
      } else {
        this._toastr.error('Complete correctamente la dirección');
      }
    }

  }

  getReclamos() {
    this._generalService.reclamos('', 'GET')
      .subscribe(res => {
        let data: any = res
        this.listaReclamos = data
        for (let i = 0; i < data.length; i++) {
          if (data[i].estado !== 'Finalizado') {
            let latlong = data[i].localizacion.split(',');
            this.markers.push({
              lat: parseFloat(latlong[0]),
              long: parseFloat(latlong[1]),
              direccion: data[i].reclamo_direccion,
              motivo: data[i].motivo.descripcion,
              label: {
                color: 'white',
                fontFamily: '',
                fontSize: '14px',
                fontWeight: 'bold',
                text: "R"
              }
            })
          }
        }
      })
    console.log(this.markers)
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // this.latitude = position.coords.latitude;
        // this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  llenarCombos() {
    this._generalService.getAreas()
      .subscribe(res => {
        this.listaAreas = res;
      })
    this._generalService.getBarrios()
      .subscribe(res => {
        this.listaBarrios = res;
      })
    this._generalService.getMotivos()
      .subscribe(res => {
        this.listaMotivos = res;
      });
  }
  extraerFecha(date: Date) {
    let day = date.getDate().toString()
    let month = (date.getMonth() + 1).toString()
    let year = date.getFullYear()
    let mes = month.toString()
    if (day.length < 2) { day = '0' + day }
    if (month.length < 2) { mes = '0' + mes }
    return day + '/' + mes + '/' + year;

  }

  direccionValida(direccionArray) {
    var valida: boolean = false;
    for (let i = 0; i < direccionArray.length; i++) {
      if (direccionArray[i].types[0] === 'street_number') {
        valida = true; this.street_number = direccionArray[i].long_name
      }

      if (direccionArray[i].types[0] === 'route') { this.street_name = direccionArray[i].long_name }
    }
    this.address = this.street_name + ' ' + this.street_number
    if (!valida) {
      this.form.controls['localizacion'].setValue("");
      this._toastr.error('La dirección no existe');
      return false;
    } else {
      return true;
    }

  }
}
@Component({
  selector: 'reclamo-modal',
  templateUrl: 'reclamo-modal.html',
})
export class ReclamoModal {

  constructor(
    public dialogRef: MatDialogRef<ReclamoModal>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
export interface menu {
  url: string;
  nombre: string;
  id: number;
}