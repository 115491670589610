<div class="bg-login lazyload" >
  <div class="aligner">
    <mat-card 
      style="padding: 0; border-radius: 6px !important; max-width: 400px; border-top: 3px solid #007199; opacity: 0.6">
      <!-- <mat-card-title color="primary" style="text-align:center; border-radius: 6px !important; padding:16px; background-color: #3f51b5; color:#fff">
          MSL - Reclamos
        </mat-card-title> -->
      <mat-card-content style="padding: 0 16px 0 16px">
        <!-- <div style="text-align:center">
            <img matListAvatar src="/assets/images/logomuni.jpg" style="cursor: pointer; height: 180px;">
          </div> -->
  

        <form id="login-form" [formGroup]="loginForm"
          (ngSubmit)="login(loginForm.value.username,loginForm.value.password)" novalidate>
          <div class="row">
            <div class="col-12" style="max-height: 48px;">
              <mat-form-field style="width: 100%;">
                <input matInput type="text" formControlName="username" placeholder="Usuario" />
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field style="width: 100%;">
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Contraseña" />
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
            <div class="col-6">
              <a style="cursor: pointer" [routerLink]="['/user/accounts/password-reset']">¿Olvido la contraseña?</a>
              <span>Version 0.0.15</span>
            </div>
            <div class="col-6">
              <button mat-stroked-button type="submit" [disabled]="!loginForm.valid" form="login-form" color="primary"
                style="width: 100%;">INGRESAR</button>
            </div>
          </div>
  
  
  
        </form>
  
      </mat-card-content>
      <!-- <mat-card-actions style="text-align: center; padding: 0 16px 16px 16px">
  
      </mat-card-actions> -->
    </mat-card>
  
  </div>
  <div class="row" style="z-index:9999; position: fixed; bottom: 50px; width: 100%;">

    <div class="col-6" style="text-align: end;">
      <img src="../../assets/images/LogoMuniSL.png" class="img-login">
    </div>
    <div class="col-6">
      <img src="../../assets/images/LogoSOSP.png" class="img-login-sosp">
    </div>
  </div>
  
</div>

<!--
    <div class="login">
  <div class="login-triangle"></div>
  
  <h2 class="login-header">Log in</h2>

  <form class="login-container">
    <p><input type="email" placeholder="Email"></p>
    <p><input type="password" placeholder="Password"></p>
    <p><input type="submit" value="Log in"></p>
  </form>
</div>
   -->