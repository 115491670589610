import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { OrdenTrabajoService } from 'src/app/services/orden-trabajo.service';
import { GeneralService } from 'src/app/services/general.service';
import { Reclamos } from 'src/app/interfaces/reclamos';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { LoadingService } from 'src/app/services/loading.service';
import { delay } from 'rxjs/operators';
import 'jspdf-autotable';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('indicatorRotate', [
      state('collapsed_more', style({ transform: 'rotate(0deg)' })),
      state('expanded_more', style({ transform: 'rotate(180deg)' })),
      transition('expanded_more <=> collapsed_more',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-Es' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})

export class ReportesComponent implements OnInit {
  loading: boolean = false;
  @ViewChild('table', { static: false }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //  @ViewChild(MatSort, {static: true}) sort: MatSort;
  isExpanded: boolean = false;
  dataSource: MatTableDataSource<any>;
  expandedElement: Reclamos | null;
  element;
  displayedColumns: string[] = ['id','nroOt','fecha', 'direccion', 'motivo', 'estado', 'barrio', 'nombre', 'accion'];

  areas: any[] = []
  listaAreas: any[] = []
  motivos: any[] = []
  listaMotivos: any[] = []
  barrios: any[] = []
  estados: any[] = ['Pendiente', "En Proceso", "Finalizado"]
  areaMotivos: any[] = []

  formFiltros: FormGroup;
  constructor(private _generalService: GeneralService, private _loading: LoadingService,
    public _toastr: ToastrService, private fb: FormBuilder) {
    this.initForm();
  }

  ngOnInit() {
    this.listenToLoading();
    this.getMotivos();
    this.getAreas();
    this.getBarrios();
    this.getReclamos();
  }

  initForm() {
    this.formFiltros = this.fb.group({
      id: [""],
      fechaDesde: [""],
      fechaHasta: [""],
      barrio: [""],
      estado: [""],
      motivo: [""],
      area: [""],
      ordenId: [""]
    })
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  getReclamos() {
    this._generalService.reclamos('', 'GET').subscribe(res => {
      var dataReclamos: any = res;
      this.dataSource = new MatTableDataSource(dataReclamos)
      this.dataSource.paginator = this.paginator;
      //  this.dataSource.sort = this.sort;
      this.paginator._intl.itemsPerPageLabel = 'Reclamos por página:';

    })
  }

  getAreas() {
    this._generalService.getAreas().subscribe(res => { let data: any = res; this.areas = data; this.listaAreas = this.areas })
  }
  getBarrios() {
    this._generalService.getBarrios().subscribe(res => { let data: any = res; this.barrios = data })
  }
  getMotivos() {
    this._generalService.getMotivos().subscribe(res => { let data: any = res; this.motivos = data; this.listaMotivos = this.motivos })
  }

  selectArea(area) {
    let areaSelect:any[];
    this.listaAreas = []
    for (let i = 0; i < this.areas.length; i++) {
      if(this.areas[i].id === Number(area)){this.listaAreas.push(this.areas[i])}
    }
    for (let i = 0; i < this.listaMotivos.length; i++) {
      if (this.listaMotivos[i].area === Number(area)){
        this.areaMotivos.push(this.listaMotivos[i])
      }
    }
    this.motivos = this.areaMotivos
    this.areaMotivos = []
  }
  filtrar() {
    if (this.formFiltros.value.id !== null && this.formFiltros.value.id !== "") {
      this._generalService.reclamos(this.formFiltros.value.id, 'GET')
        .subscribe(res => {
          let data: any = res;
          this.dataSource = new MatTableDataSource([data])
          this.dataSource.paginator = this.paginator;
          this.paginator._intl.itemsPerPageLabel = 'Reclamos por página:';
        })
    } else {
      if(this.formFiltros.value.fechaDesde === null){this.formFiltros.controls['fechaDesde'].setValue("")}
      if(this.formFiltros.value.fechaHasta === null){this.formFiltros.controls['fechaHasta'].setValue("")}
      this._generalService.getReclamosFiltro(this.formFiltros.value.fechaDesde, this.formFiltros.value.fechaHasta,
        this.formFiltros.value.motivo, this.formFiltros.value.barrio,
        this.formFiltros.value.estado, this.formFiltros.value.ordenId,
        this.formFiltros.value.area)
        .subscribe(res => {
          let data: any = res;
          this.dataSource = new MatTableDataSource(data)
          this.dataSource.paginator = this.paginator;
          this.paginator._intl.itemsPerPageLabel = 'Reclamos por página:';
        })
    }

  }
  resetFiltros() {
    this.formFiltros.reset()
    this.initForm();
    this.getReclamos()
    this.getMotivos()
    this.getAreas()
  }
  imprimir() {
    // TODO arma pdf
    const doc = new jsPDF('l')
    doc.text('Lista de reclamos', 15, 20, {
    })
    let body: any[] = []
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].barrio === null) { this.dataSource.data[i].barrio = "" } else {
        this.dataSource.data[i].barrio = this.dataSource.data[i].barrio
      }
      body.push([this.dataSource.data[i].id,this.dataSource.data[i].orden_trabajo,this.dataSource.data[i].fecha_ingreso, this.dataSource.data[i].reclamo_direccion, this.dataSource.data[i].motivo.descripcion, this.dataSource.data[i].estado,
      this.dataSource.data[i].barrio.nombre, this.dataSource.data[i].vecino_nombre])
    }
    console.log('body, ', ...body)
    doc.autoTable({
      head: [['id', 'Orden', 'Fecha', 'Dirección', 'Motivo', 'Estado', 'Barrio', 'Nombre']],
      body: [
        ...body
        // ...
      ],
      startY: 30,
      columnStyles: {
        0: {cellWidth: 10},
        1: {cellWidth: 15},
        2: {cellWidth: 25},
        3: {cellWidth: 60},
        4: {cellWidth: 60},
        // etc
      },
      headerStyles: {
        // fontSize: 6,
        // font: 'PTSans'
    }
    })
    // doc.autoTable({
    //   html: 'table',styles: {
    //     halign: 'left'
    //   },
    //   startY: 30,
    //   headerStyles: {
    //     lineWidth: 0.5,
    //     lineColor: [255, 255, 255]
    // }
    // });
    doc.setProperties({
      title: "Reclamos"
    });
    this.filtrar()
    doc.save('reclamos.pdf');
  }
  convertirFecha(fecha, key) {
    console.log(fecha)
    if (key === 'desde') { this.formFiltros.controls['fechaDesde'].setValue(fecha) }
    if (key === 'hasta') { this.formFiltros.controls['fechaHasta'].setValue(fecha) }
  }
}
