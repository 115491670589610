import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { IngresoReclamoComponent } from './pages/ingreso-reclamo/ingreso-reclamo.component';
import { AuthGuard } from './guards/auth.guard';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SeguimientoReclamosComponent } from './pages/seguimiento-reclamos/seguimiento-reclamos.component';
import { OrdenTrabajoComponent } from './pages/orden-trabajo/orden-trabajo.component';
import { HomeComponent } from './pages/home/home.component';
import { ReportesComponent } from './pages/reportes/reportes.component';
import { ConsultaOrdenesComponent } from './pages/consulta-ordenes/consulta-ordenes.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'user/accounts/password-reset', component: ResetPasswordComponent },
  { path: 'informes', component: ReportesComponent, canActivate: [AuthGuard] },
  { path: 'alta-reclamo', component: IngresoReclamoComponent, data:{title: 'Reclamos'}, canActivate: [AuthGuard]},
  { path: 'seguimiento', component: SeguimientoReclamosComponent, data:{title: 'Seguimiento'}, canActivate: [AuthGuard]},
  { path: 'generar-ordenes', component: OrdenTrabajoComponent, data:{title: 'Ordenes de trabajo'}, canActivate: [AuthGuard]},
  { path: 'consulta-ordenes', component: ConsultaOrdenesComponent, data:{title: 'Consulta de trabajo'}, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
