import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router, Route } from '@angular/router'
import { GeneralService } from './services/general.service';
import { StorageService } from './services/storage.service';
import { VersionCheckService } from './services/versionCheck.service';
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { WorkersService } from './services/workers.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit {
  title = 'Secretaría de Obras y Servicios Públicos';
  opened: boolean = false;
  auth: boolean = false;
  listaMenu: menu[] = [];
  count: any = 0;
  user: any[] = [];
  update: boolean = false;
  csrftoken: string
  @ViewChild(MatSidenav, { static: false }) sidenav: MatSidenav;

  constructor(public _router: Router, public _generalServices: GeneralService, public updates: SwUpdate,
    public _storageService: StorageService, public versionCheck: VersionCheckService,
    public workerService: WorkersService) {

  }

  ngOnInit() {
    // this.count = sessionStorage.getItem('count')
    // console.log(this.count)
    // if(this.count === null){ 
    //   this.count = 1;
    //   sessionStorage.setItem('count', this.count)
    //   document.location.reload()}
    this.csrftoken = this.getCookie('csrftoken')
    sessionStorage.setItem('csrftoken', this.csrftoken)
 
  this.update =  this.workerService.checkUpdates()
 
      this.versionCheck.initVersionCheck(environment.versionCheckURL);
  
      this.updates.available.subscribe(event => {
        console.log('updating to new version');
        this.updates.activateUpdate().then(() => document.location.reload());
      });

  }

  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
   }
   
  recibirMensaje(mensaje: menu[]) {

    this.opened = false
    this.listaMenu = mensaje;
  }



}
export interface menu {
  url: string,
  nombre: string,
  id: number,
  group: any
}