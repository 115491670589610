<div *ngIf="loading" class="loading-container flex-content-center">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h5 class="bg-informes">Informes</h5>
        </div>
    </div>

    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filtros
            </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="formFiltros">
            <div class="row mt-2">
                <div class="col-sm-12 col-md-1 col-lg-1">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Id </mat-label>
                        <input matInput placeholder="Id Reclamo" formControlName="id">
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Fecha Ingreso desde</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Fecha ingreso desde"
                            formControlName="fechaDesde" matTooltip="Fecha Ingreso desde"
                            (dateInput)="convertirFecha($event.value.format('YYYY-MM-DD'), 'desde')">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Fecha ingreso hasta</mat-label>
                        <input matInput [matDatepicker]="picker2" placeholder="Fecha ingreso hasta"
                            formControlName="fechaHasta" matTooltip="Fecha Ingreso Hasta"
                            (dateInput)="convertirFecha($event.value.format('YYYY-MM-DD'), 'hasta')">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-2 col-lg-2">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Estado</mat-label>
                        <mat-select id="estado" formControlName="estado">
                            <mat-option *ngFor="let estado of estados" value={{estado}}>
                                {{estado}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Barrio</mat-label>
                        <mat-select id="barrio" formControlName="barrio">
                            <mat-option *ngFor="let barrio of barrios" value={{barrio.id}}>
                                {{barrio.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <!-- <div class="col-4">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Orden de trabajo</mat-label>
                    <input id="orden" matInput placeholder="Orden de trabajo" formControlName="ordenId">
                </mat-form-field>
            </div> -->
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Área</mat-label>
                        <mat-select id="area" formControlName="area" (selectionChange)="selectArea($event.value)">
                            <mat-option *ngFor="let area of areas" value={{area.id}}>
                                {{area.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-5 col-lg-5">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Motivo</mat-label>
                        <mat-select id="motivo" formControlName="motivo">
                            <mat-optgroup *ngFor="let area of listaAreas" [label]="area.nombre">
                                <ng-container *ngFor="let motivo of motivos">
                                    <mat-option *ngIf="motivo.area === area.id" value={{motivo.id}}>
                                        {{motivo.descripcion}}
                                    </mat-option>
                                </ng-container>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3">
                    <mat-button-toggle-group #group="matButtonToggleGroup" color="primary">
                        <!-- <button mat-button mat-raised-button class="buttons-informes" (click)="filtrar()"
                            aria-label="Text align left">
                            BUSCAR
                        </button> -->
                        <mat-button-toggle class="buttons-informes" (click)="filtrar()"
                            aria-label="Text align left">
                            BUSCAR
                        </mat-button-toggle>
                        <mat-button-toggle class="buttons-informes" (click)="resetFiltros()"
                            aria-label="Text align left">
                            LIMPIAR
                        </mat-button-toggle>
                        <mat-button-toggle class="buttons-informes" (click)="imprimir()" aria-label="Text align left">
                            PDF
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <!-- <div style="padding-top: 10px; font-size: 12px;">
                    <button mat-button mat-raised-button color="primary" (click)="filtrar()">Filtrar</button>
                    <button mat-button mat-raised-button (click)="resetFiltros()" style="margin-left: 10px;">Limpiar
                        filtros</button>
                    <button mat-button color="primary" mat-raised-button (click)="imprimir()"
                        style="margin-left: 10px;">Generar PDF</button>
                </div> -->
                </div>
            </div>
        </form>
    </mat-expansion-panel>
    <table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8 mt-4" multiTemplateDataRows>

        <!-- Position Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="nroOt">
            <th mat-header-cell *matHeaderCellDef> Nº Orden </th>
            <td mat-cell *matCellDef="let element">{{element.orden_trabajo}}</td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef> Fecha </th>
            <td mat-cell *matCellDef="let element">{{element.fecha_ingreso}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element">{{element.vecino_nombre}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="direccion">
            <th mat-header-cell *matHeaderCellDef> Dirección </th>
            <td mat-cell *matCellDef="let element"> {{element.reclamo_direccion}}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="barrio">
            <th mat-header-cell *matHeaderCellDef> Barrio </th>
            <td mat-cell *matCellDef="let element"> {{element.barrio?.nombre}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
        </ng-container>

        <ng-container matColumnDef="motivo">
            <th mat-header-cell *matHeaderCellDef> Motivo </th>
            <td mat-cell *matCellDef="let element"> {{element.motivo.descripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.comentario_extra || element.comentarios.length > 0" class="menu-button"
                    [ngClass]="{'rotated' : isExpanded && element == expandedElement}"
                    (click)="isExpanded = !isExpanded;expandedElement = expandedElement === element ? null : element">
                    <img src="../../../assets/images/FlechaAbajo.png" class="icons"></mat-icon>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-description">
                        <p [innerHTML]="element.comentario_extra"></p>
                        <!-- <span class="example-element-description-attribution"> </span> -->
                        <div class="inbox_chat" style="overflow: hidden;">
                            <div class="chat_list active_chat" *ngFor="let coment of element.comentarios">
                                <div class="chat_people">
                                    <div class="chat_ib">
                                        <h5>{{coment.user_username}} <span
                                                class="chat_date">{{coment.fecha_comentario}}</span></h5>
                                        <p>{{coment.comentario}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[20,50,100]"></mat-paginator>
</div>