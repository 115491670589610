<button class="close" mat-button (click)="cerrar()">X</button>
<!-- <h3 mat-dialog-title *ngIf="action === 'Comentario'"><strong>{{local_data.fecha_ingreso}}</strong></h3> -->
<div mat-dialog-content *ngIf="action === 'Comentario'">
  <span><strong>Fecha:</strong> {{local_data.fecha_ingreso}}, <strong>Dirección:</strong> {{local_data.reclamo_direccion}}, <strong>Motivo:</strong> {{local_data.motivo.descripcion}}</span>
  <mat-form-field style="width: 100%;">
    <textarea placeholder="{{action}}" matInput [(ngModel)]="coment"></textarea>
  </mat-form-field>
  <div style="display:block; text-align:right">
    <button mat-button mat-flat-button color="primary" (click)="doAction()">Agregar</button>
    <!-- <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button> -->
  </div>
  <div ><span>Historial</span></div>

    <div class="inbox_chat">
      <div class="chat_list active_chat" *ngFor="let coment of comentarios">
        <div class="chat_people">
          <div class="chat_ib">
            <h5>{{coment.user_username}} <span class="chat_date">{{coment.fecha_comentario}}</span></h5>
            <p>{{coment.comentario}}</p>
          </div>
        </div>
      </div>
    </div>
    
</div>

<h1 mat-dialog-title *ngIf="action === 'Finalizar'"><strong>{{action}}</strong></h1>
<div mat-dialog-content *ngIf="action === 'Finalizar'">
<span>¿Esta seguro que desea finalizar el reclamo?</span>
<p><span><strong>Fecha:</strong> {{local_data.fecha_ingreso}}, <strong>Direccion:</strong> {{local_data.reclamo_direccion}}, <strong>Motivo:</strong> {{local_data.motivo.descripcion}}</span></p>
  <div style="display:block; text-align:right">
    <button mat-button mat-flat-button color="primary" (click)="doAction()">Confirmar</button>
    <button mat-button (click)="closeDialog()" mat-flat-button style="background-color: grey; color:white">Cancelar</button>
  </div>
</div>