import { NgModule } from '@angular/core';

import {
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatBadgeModule,
    MatCardModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatTreeModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatDividerModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    MatTabsModule,
    MatMenuModule
} from '@angular/material';

@NgModule({
    
    imports: [
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        MatButtonModule,
        MatBadgeModule,
        MatCardModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
        MatTreeModule,
        MatProgressSpinnerModule,
        MatOptionModule,
        MatSelectModule,
        MatTableModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatDialogModule,
        MatDividerModule,
        MatPaginatorModule,
        MatSortModule,
        MatRadioModule,
        MatTabsModule,
        MatMenuModule
    ],
    exports: [
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatListModule,
        MatButtonModule,
        MatBadgeModule,
        MatCardModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatTreeModule,
        MatProgressSpinnerModule,
        MatOptionModule,
        MatSelectModule,
        MatTableModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatDialogModule,
        MatDividerModule,
        MatPaginatorModule,
        MatSortModule,
        MatRadioModule,
        MatTabsModule,
        MatMenuModule
    ]    
  })
  export class MyMaterialModule { }