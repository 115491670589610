import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../auth/authentication.service';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { Session } from '../interfaces/session'
import { ToastrService } from 'ngx-toastr';
import { SwUpdate } from '@angular/service-worker';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [StorageService]
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public submitted: Boolean = false;
  public error: { code: number, message: string } = null;
  hide: boolean = true
  reset: boolean = false;
  loading: boolean = false;
  csrftoken: string
  session: Session = { token: '', user: '' }
  radioSelected: any;
  enum_details = [
    {name: 'pardeep'},
    {name: 'Jain'},
    {name: 'Angular'},
  ]
  constructor(private formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private _toastr: ToastrService, public updates: SwUpdate,
    private _activateRoute: Router, public _storageService: StorageService) { }

  ngOnInit() {
   
    this.csrftoken = this.getCookie('csrftoken')
    sessionStorage.setItem('csrftoken', this.csrftoken)

    this.updates.available.subscribe(event => {
      console.log('updating to new version');
      this.updates.activateUpdate().then(() => document.location.reload());
    });
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    })
    if (this._storageService.isAuthenticated()) {
      this._activateRoute.navigate(['/alta-reclamo']);
    } //else { sessionStorage.clear();}
  }
  
  login(username,password) {
    this._authenticationService.login(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe(res => {
        let data: any = res
        this.session.token = data.token
        this.session.user = data.user
        this._storageService.setCurrentSession(this.session)
        sessionStorage.setItem('TOKEN', data.token)
        sessionStorage.setItem('group', JSON.stringify(data.user.groups))

        this._activateRoute.navigate(['/home']);
      }, error => {
        this._toastr.error('Usuario o contraseña incorrectos')
      })
  }

  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
   }

}
