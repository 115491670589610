import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { MatSnackBar } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from 'src/app/services/loading.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  hide: boolean = true;
  public submitted: Boolean = false;
  public error: {code: number, message: string} = null;
  loading: boolean = true;
  resetForm: FormGroup
  token: any;
  confirmPass: boolean = false
  confirmForm: FormGroup;
  reset: boolean = true;
  @ViewChild('formDirective',{static: false}) private formDirective: NgForm;

  constructor(private _formBuilder: FormBuilder, private _activateRoute: ActivatedRoute, private _router: Router,
    private _authService: AuthenticationService, public _snackBar: MatSnackBar, public _toastr: ToastrService,
    private _loading: LoadingService) {

    this.resetForm = this._formBuilder.group({
      email: ['',Validators.required]
    })
    this.confirmForm = this._formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['',Validators.required]
    }, { validators: this.checkPasswords })
    this._activateRoute.queryParams
      .subscribe((params) => {
        this.token = params.token;
        console.log(this.token)
        if(this.token !== undefined){
          this.confirmPass = true
        }
      });
  }

  ngOnInit() {
    this.listenToLoading();
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  openSnackBar(message) {
    this._snackBar.open(message, 'Cerrar', {
      duration: 3000
    });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  console.log(group)
  const password = group.value.password;
  const confirmPassword = group.value.confirmPassword;

  return password === confirmPassword ? null : { notSame: true }     
}

  resetPassword(){
    this._authService.resetPassword(this.resetForm.value.email)
      .subscribe(res => {
       // this.formDirective.resetForm()
        this._toastr.success('Revise su casilla de correo para continuar con la recuperación de la contraseña')
      }, error => {
        if(error.status === 400){this._toastr.error('Introduzca una dirección de correo electrónico válida')}
        
      })
  }

  confirmPassword(){
    this._authService.confirmPassword(this.confirmForm.value.password, this.token)
      .subscribe(res => {
        this._toastr.success('Contraseña generada')
        this._router.navigate(['/'])
      }, error => {
        this._toastr.error('Verifique que las contraseñas coincidan')
        
      })
  }
}
