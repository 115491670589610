import { Component, Input, Optional, Host, OnInit, Inject } from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';
import { filter } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralService } from 'src/app/services/general.service';
@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditComponent implements OnInit {

  local_data: any;
  fechaActual: Date = new Date()
  action: string;
  coment: string
  comentarios: any[] = []
  actualizado: boolean = false;
  finalizado: boolean = false;
  currentUser: any;

  constructor(public dialogRef: MatDialogRef<InlineEditComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private _generalService: GeneralService) {
    console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action;
    }

    ngOnInit() {
     this.comentarios = this.local_data.comentarios
     console.log(this.comentarios)
     this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
    }

    doAction(){
      this.actualizado = true
      if(this.action === 'Comentario'){
        let body = {
          "user": this.currentUser.user.id,
          "fecha_comentario": this.fechaActual,
          "reclamo": this.local_data.id,
          "comentario": this.coment
        }
         this._generalService.comentarios(body).subscribe(res =>{
          var data: any = res 
          this.comentarios.push(data);
          this.comentarios = this.comentarios.reverse();
          this.coment = ""
         })
      } else if(this.action === 'Finalizar'){
        this.finalizado = true
        let fecha = this.local_data.fecha_ingreso.split('/')
        this.local_data.fecha_ingreso = fecha[2]+'-'+fecha[1]+'-'+fecha[0]
        if(this.local_data.vecino_telefono === null){ this.local_data.vecino_telefono = ""}
        this.local_data.estado = 'Finalizado';
        this.dialogRef.close({finalizado: this.finalizado, data:this.local_data})
      }
     // this.dialogRef.close({event:this.action,data:this.local_data});
    }
    cerrar(){
      this.dialogRef.close({event:this.actualizado})
    }
    closeDialog(){
      this.dialogRef.close();
    }

  }
