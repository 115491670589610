import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { MapsAPILoader } from '@agm/core';
import { FormGroup } from '@angular/forms';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Reclamos, ReclamosArea } from 'src/app/interfaces/reclamos';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LoadingService } from 'src/app/services/loading.service';
import { delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatDialog, MatTable, MatExpansionPanel, MatAccordion } from '@angular/material';
import { InlineEditComponent } from './inline-edit/inline-edit.component';

@Component({
  selector: 'app-seguimiento-reclamos',
  templateUrl: './seguimiento-reclamos.component.html',
  styleUrls: ['./seguimiento-reclamos.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('indicatorRotate', [
      state('collapsed_more', style({ transform: 'rotate(0deg)' })),
      state('expanded_more', style({ transform: 'rotate(180deg)' })),
      transition('expanded_more <=> collapsed_more',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
  ],
})
export class SeguimientoReclamosComponent implements OnInit {
  title = "Seguimiento"
  position = "above"
  loading: boolean = true;
  @ViewChild('table', { static: true }) table: MatTable<any>;
  @ViewChild('myaccordion', { static: true}) panel: MatAccordion;
  reclamos: Reclamos[] = [];
  displayedColumns: string[] = ['id', 'fecha', 'direccion', 'motivo', 'barrio', 'nombre', 'accion'];
  isExpanded: boolean = false;
  reclamosPorArea: ReclamosArea[] = [];
  reclamosPorAreaFilter: ReclamosArea[] = [];
  selection = new SelectionModel<Reclamos>(true, []);
  expandedElement: Reclamos | null;
  element;
  dataSource: MatTableDataSource<ReclamosArea> = new MatTableDataSource([]);


  form: FormGroup;
  latitude: number = -32.96164110559714;
  longitude: number = -60.645116020507814;
  zoom: number = 12;
  step:number;

  public searchElementRef: ElementRef;

  constructor(private _generalService: GeneralService, public dialog: MatDialog,
    private _toastr: ToastrService, private _loading: LoadingService) { }

  ngOnInit() {
    this.listenToLoading()
    this.getReclamos()
    console.log(this.panel)
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  setStep(index: number) {
    this.step = index;
  }

  getReclamos() {
    this._generalService.getReclamosArea()
      .subscribe(res => {
        let data: any = res
        this.reclamosPorArea = data
        this.reclamosPorAreaFilter = []
        for (let i = 0; i < this.reclamosPorArea.length; i++) {
          this.reclamos = []
          this.reclamosPorAreaFilter.push(this.reclamosPorArea[i])
          console.log(this.reclamosPorAreaFilter)
          for (let j = 0; j < this.reclamosPorArea[i].reclamos.length; j++) {
            if (this.reclamosPorArea[i].reclamos[j].estado === 'En Proceso') {
              let direccion = this.reclamosPorArea[i].reclamos[j].reclamo_direccion.split(',');
              this.reclamosPorArea[i].reclamos[j].reclamo_direccion = direccion[0]
              this.reclamos.push(this.reclamosPorArea[i].reclamos[j])

            }
          }
          this.reclamosPorAreaFilter[i].reclamos = this.reclamos
        }
        this.dataSource = new MatTableDataSource(this.reclamosPorAreaFilter)
      })
  }
  ver(row) {
    if (!row.check || row.check === undefined) { row.check = true } else { row.check = false }

  }
  openDialog(action, obj) {
    if (action === 'Comentario') { var width = '1200px' } else { var width = 'auto' }
    obj.action = action;
    const dialogRef = this.dialog.open(InlineEditComponent, {
      width: width,
      maxWidth: '1120px',
      data: obj,
      panelClass: 'my-dialog',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event) {
        this.getReclamos()
      } else if (result.finalizado) {
        console.log('FINALIZANDO...')
        let body = { "estado": result.data.estado }
        this._generalService.reclamosEstado(result.data.id, body).subscribe(res => { this.getReclamos() })
      }
    });
  }
 
  updateRowData(row_obj) {
    // console.log(row_obj)
    // this.dataSource1.data = this.dataSource1.data.filter((value,key)=>{
    //   if(value.id == row_obj.id){
    //     value.reclamo_direccion = row_obj.reclamo_direccion;
    //   }
    // //  this.table.renderRows()
    //   return true;
    // });
  }

  finalizarReclamos() {
    console.log(this.dataSource.data)
    for (let i = 0; i < this.reclamos.length; i++) {

      for (let j = 0; j < this.dataSource.data.length; j++) {
        if (this.dataSource.data[i].reclamos[j].check) {
          this.dataSource.data[i].reclamos[j].estado = 'Finalizado'

          if (this.dataSource.data[i].reclamos[j].vecino_telefono === null) { this.dataSource.data[i].reclamos[j].vecino_telefono = "" }
          this._generalService.reclamos(this.dataSource.data[i].reclamos[j], 'PATCH').subscribe(res => { console.log(res) })
        }
        if (!this.dataSource.data[i].reclamos[j].check) {
          if (this.dataSource.data[i].reclamos[j].vecino_telefono === null) { this.dataSource.data[i].reclamos[j].vecino_telefono = "" }

          this._generalService.reclamos(this.dataSource.data[i].reclamos[j], 'PATCH').subscribe(res => { console.log(res) })
        }
      }
    }
    this._toastr.success('Reclamos actualizados');
    setTimeout(() => {
      this.getReclamos()
    }, 200);
  }
}
export interface Element {
  comment?: string;
}


/**
 * Data source to provide what data should be rendered in the table. The observable provided
 * in connect should emit exactly the data that should be rendered by the table. If the data is
 * altered, the observable should emit that new set of data on the stream. In our case here,
 * we return a stream that contains only one set of data that doesn't change.
 */
export class ReclamosDataSource extends DataSource<any> {

  private dataSubject = new BehaviorSubject<any[]>([]);

  data() {
    return this.dataSubject.value;
  }

  update(data) {
    this.dataSubject.next(data);
  }

  constructor(data: Reclamos[]) {
    super();
    this.dataSubject.next(data);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    return this.dataSubject;
  }

  disconnect() { }
}