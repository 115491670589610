<!-- <div style="text-align:right"><mat-icon color="warn" style="vertical-align: middle; font-size: 30px;">warning</mat-icon></div> -->
<h1 mat-dialog-title align="center">¡ATENCIÓN!</h1>
<!-- <h3 mat-dialog-title *ngIf="action === 'Comentario'"><strong>{{local_data.fecha_ingreso}}</strong></h3> -->
<div mat-dialog-content id="modal-reclamo">
    <span><strong>Usted esta por guardar una dirección que momentaneamente no se encuentra en el mapa de San Lorenzo. ¿Desea guardar la direccion?</strong></span>
    <div mat-dialog-actions align="end">
        
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="bg-reclamos">Si</button>
        <button mat-button (click)="onNoClick()">No</button>
    </div>

</div>