import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
//import { Observable } from 'rxjs'
//import { map } from "rxjs/operators";
//import { HttpClient } from '@angular/common/http';
//import { map } from 'rxjs/internal/operators/map';
// import 'rxjs/Rx';
import { map, catchError } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';

@Injectable()
export class AppConfig {

  private appConfig: any;
  private http : HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
    }

  loadAppConfig() {
    return this.http.get('/assets/env/env.json')
      .toPromise()
      .then(config => {
        this.appConfig = config;
      });
  }

  apiBaseUrl() : string {
    return this.appConfig.apiBaseUrl;
  }
}