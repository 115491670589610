import {Injectable} from "@angular/core";
import { Router } from '@angular/router';
import {Session} from "../interfaces/session";
import {User} from "../interfaces/user"

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private sessionStorageService;
  private currentSession : Session = null;

  constructor(private router: Router) { 
    this.sessionStorageService = sessionStorage;
    this.currentSession = this.loadSessionData();
  }

  setCurrentSession(session: Session): void {
    this.currentSession = session;
    this.sessionStorageService.setItem('currentUser', JSON.stringify(session));
  }
  loadSessionData(): Session{
    var sessionStr = this.sessionStorageService.getItem('currentUser');
    return (sessionStr) ? <Session> JSON.parse(sessionStr) : null;
  }
  getCurrentSession(): Session {
    return this.currentSession;
  }
  removeCurrentSession(): void {
    this.sessionStorageService.removeItem('TOKEN');
    this.currentSession = null;
  }
  // getCurrentUser(): User {
  //   var session: Session = this.getCurrentSession();
  //   return (session && session.user) ? session.user : null;
  // };
  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  };
  getCurrentToken(): string {
    var session = sessionStorage.getItem('TOKEN')
    // var session = this.getCurrentSession();
     return (session) ? session : null;
  };
  logout(): void{
    this.removeCurrentSession();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

}
